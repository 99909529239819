<template>
  <section :id="'timeline-section-' + pagePosition" ref="section" class="-visibility-auto dual-tile" :data-index="pagePosition">
    <Observer name="dual-enter" @enter="enter">
      <div class="container">
        <div v-if="item" class="row justify-content-center align-items-center">
          <div class="col-12 col-md-10 header">
            <HeadlineTitleText :item="item"></HeadlineTitleText>
          </div>
        </div>
        <div v-if="items" class="row justify-content-center">
          <template v-for="(bloc, i) in items">
            <template v-if="declination === 'expertise' && i === 0">
              <div class="col-12 col-md-5 tile order-1 align-self-center" :key="'content_' + i" :class="{'-expertise': declination === 'expertise', 'offset-md-1' : item.side !== 'left', 'order-md-0' : item.side === 'left'}">
                <Observer name="fade-up">
                  <h2 class="title" v-if="bloc.title" v-text="bloc.title"></h2>
                  <p class="text" v-if="bloc.text" v-html="bloc.text"></p>
                  <router-link v-if="bloc.ctaLink && bloc.ctaText" :to="localizedUrl + bloc.ctaLink" class="link-light" v-text="bloc.ctaText"></router-link>
                </Observer>
              </div>
              <div class="col-12 col-md-6 tile order-0" :key="'image_' + i" :class="{'-expertise': declination === 'expertise', 'order-md-1 offset-md-1' : item.side === 'left'}">
                <Observer name="fade-up">
                  <div class="bloc -visual-only" :class="{'-white': bloc.headlineColor === 'white'}">
                    <lazy-picture :item="bloc" desktop-ratio="1.45" mobile-ratio="1.5"></lazy-picture>
                    <button type="button" class="btn-play" @click="playVideo = true" v-if="bloc.video">
                      <span>{{ $t('Play') }}</span>
                    </button>
                    <h3 v-if="bloc.headline" v-text="bloc.headline" class="headline" :class="{'-dark': bloc.headlineColor === 'dark'}"></h3>
                  </div>
                </Observer>
              </div>
            </template>
            <div class="col-12 col-md-6 tile order-2" :class="{'-expertise': declination === 'expertise'}" :key="bloc.title + '_' + i" v-else>
              <Observer name="fade-up">
                <div class="bloc" :class="{'-white': bloc.headlineColor === 'white'}">
                  <template v-if="declination === 'expertise'">
                    <lazy-picture :item="bloc" desktop-ratio="0.64" mobile-ratio="1"></lazy-picture>
                  </template>
                  <template v-else>
                    <lazy-picture :item="bloc" desktop-ratio="1.45" mobile-ratio="1.5"></lazy-picture>
                  </template>
                  <h3 v-if="bloc.headline" v-text="bloc.headline" class="headline" :class="{'-dark': bloc.headlineColor === 'dark'}"></h3>
                </div>
                <h2 class="title" v-if="bloc.title" v-text="bloc.title"></h2>
                <p class="text" v-if="bloc.text" v-html="bloc.text"></p>
                <router-link v-if="bloc.ctaLink && bloc.ctaText" :to="localizedUrl + bloc.ctaLink" class="link-light" v-text="bloc.ctaText"></router-link>
              </Observer>
            </div>
          </template>
        </div>
      </div>
    </Observer>
    <VideoPlayerFS :play-video="playVideo" :item="items[0]" v-if="items[0].video" @closeVideo="() => { playVideo = false }"></VideoPlayerFS>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from '../components/utils/LazyPicture'
import VideoPlayerFS from './VideoPlayerFS'
import HeadlineTitleText from '../components/utils/HeadlineTitleText'

export default {
  mixins: [ReadyHook],
  name: 'generic_grid',
  props: ['item', 'items', 'pagePosition', 'declination', 'siblings-components'],
  components: {
    LazyPicture,
    VideoPlayerFS,
    HeadlineTitleText
  },
  data () {
    return {
      playVideo: false,
      appeared: false
    }
  },
  computed: {
    localizedUrl () {
      const { url } = currentStoreView()
      return url || ''
    }
  },
  methods: {
    enter () {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    }
  },
  created () {},
  mounted () {},
  destroyed () {}
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  margin-bottom: 8rem;
  @include bp(max md) {
    margin-bottom: 4rem;
  }

  .header {
    margin-bottom: 8rem;
    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }

  .col-12:not(.header) {
    margin-bottom: 4rem;
  }
}

.tile.col-md-6:not(.-expertise) {
  padding-right: 4rem;

  @include bp(max md) {
    padding-right: 1.5rem;
    margin-bottom: 4rem;
  }

  &:last-child {
    padding-left: 4rem;
    @include bp(max md) {
      padding-left: 1.5rem;
    }
  }
}

.tile {
  .bloc {
    position: relative;
    overflow: hidden;
    height: calc(50vw * 1.36);
    max-height: 80rem;
    padding: 2rem;
    margin-bottom: 4rem;

    &:before {
      display: block;
      content: '';
      position: absolute;
      z-index: 15;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
    }

    &.-white {
      &:before {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    @include bp(max md) {
      padding: 2rem;
      height: auto;
      min-height: calc(100vw * 1.5);
      max-height: none;
    }

    .headline {
      position: absolute;
      top: 2rem;
      left: 2rem;
      right: 2rem;
      z-index: 20;
      font-weight: normal;
      font-size: em(14);
      letter-spacing: em(2, 14);
      text-transform: uppercase;
      color: var(--zenith-white);

      opacity: 1;
      @include appearTitle(800ms);

      &.-dark {
        color: var(--zenith-theme-primary);
      }
    }

    .lazy-picture {
      /deep/ img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .btn-play {
      position: absolute;
      z-index: 20;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    display: block;
    position: relative;
    z-index: 20;
    font-size: em(26);
    line-height: em(38, 26);
    letter-spacing: 0;
    font-weight: normal;
    color: var(--zenith-theme-primary);
    margin-bottom: 2rem;
    opacity: 1;
    transform: translateY(0);
    @include appearTitle(900ms);

    @include bp(max md) {
      margin-bottom: 2rem;
      font-size: em(18);
      line-height: em(24, 18);
    }
  }

  .text {
    display: block;
    position: relative;
    z-index: 20;
    font-size: em(16);
    line-height: em(28, 16);
    letter-spacing: 0;
    font-weight: normal;
    color: var(--zenith-grey);
    margin-bottom: 2rem;
    opacity: 1;
    transform: translateY(0);
    @include appearTitle(900ms);

    @include bp(max md) {
      margin-bottom: 2rem;
    }
  }

  button,
  a {
    position: relative;
    z-index: 20;
    @include appear(1200ms);
  }

  &.-expertise {

    @include bp(md) {
      margin-bottom: 0 !important;
    }

    @include bp(max md) {
      &.order-0 {
        margin-bottom: 2.4rem !important;
      }
    }

    .bloc {
      margin-bottom: 3rem;
      @include bp(max md) {
        margin-bottom: 2.4rem;
      }

      &:not(.-visual-only) {
        height: calc(50vw * 0.64);
        max-height: 35rem;

        @include bp(max md) {
          min-height: auto;
          max-height: none;
          height: calc(100vw - 3rem);
        }
      }

      &.-visual-only {
        @include bp(max md) {
          margin-bottom: 0;
        }
      }
    }

    .title {
      @include bp(max md) {
        font-size: em(26);
        line-height: em(30, 26);
      }
    }

    /deep/ .obs {
      height: 100%;
      display: flex;
      flex-direction: column;

      .link-light {
        margin-top: auto;
        width: fit-content;
      }
    }
  }
}

</style>
